import React, { useEffect, useState, useContext, useMemo } from "react";
import { injectIntl } from "react-intl";
import { v1 as uuidv1 } from "uuid";

import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import TypeButton from "../ButtonPanel/TypeButton/TypeButton";
import { createBaseEvent } from "../../Base/Utilities/CreateBaseEvent";
import { getEventTime, sendEvent } from "../../Base/Utilities/SendEvent";

import "./Shootout.scss";
import PlayerSelect from "./PlayerSelect/PlayerSelect";
import { ResultBox } from "./ResultBox/ResultBox";
import ConfirmModal from "./ConfirmModal/ConfirmModal";
import { Input } from "reactstrap";
import Checkbox from "../../Base/Checkbox/Checkbox";
import { sendClockEvent } from "../../Base/Utilities/ClockEvents";

function getTossWinner(events) {
  return events?.find((event) => event.eventType === "shootOutToss" && event.subType === "won") ?? "";
}

function getActiveGks(entities, events) {
  return entities?.reduce((soFar, entity) => {
    return {
      ...soFar,
      [entity.entityId]:
        events?.findLast((event) => event.eventType === "shootOutDefence" && event.entityId === entity.entityId)
          ?.personId ?? "",
    };
  }, {});
}

const Shootout = (props) => {
  const { currentState, intl, updateState } = props;
  const { entities, period, mqtt } = currentState;
  const { fixtureProfile, sport } = useContext(FixtureDetailsContext);
  const { formatMessage } = intl;
  const [selected, setSelected] = useState({
    entityId: "",
    personId: "",
  });

  const [attemptsPlayId, setAttemptsPlayId] = useState("");
  const [tossWinner, setTossWinner] = useState(getTossWinner(currentState.mqtt?.plays)?.entityId ?? "");
  const [nextShooterEntityId, setNextShooterEntity] = useState("");
  const [teamSelects, setTeamSelects] = useState();
  const [activeGks, setActiveGks] = useState(getActiveGks(entities, mqtt?.plays));
  const [confirmModalFor, setConfirmModalFor] = useState("");
  const [attemptEvent, setAttemptEvent] = useState(null);

  const [isPenaltyStroke, setIsPenaltyStroke] = useState(false);

  const hasShootOutStarted = useMemo(() => {
    return !!mqtt?.plays?.find((event) => event.eventType === "shootOutAttempt");
  }, [mqtt.plays]);

  useEffect(() => {
    const pendingAttemptEvent = findPendingAttemptEvent();
    if (!!pendingAttemptEvent) {
      setAttemptEvent(pendingAttemptEvent);
      setSelected({
        personId: pendingAttemptEvent.personId,
        entityId: pendingAttemptEvent.entityId,
      });
    }
  }, []);

  useEffect(() => {
    if (!!attemptsPlayId && selected?.entityId && selected?.personId) {
      if (attemptEvent === null) {
        const newAttemptEvent = createAttemptEvent();
        if (sport === "handball") {
          sendEvent(
            {
              action: "sendEvent",
              value: "new",
              type: "custom",
              event: newAttemptEvent,
            },
            props,
          );
        }
        setAttemptEvent(newAttemptEvent);
      } else {
        const updatedAttemptEvent = updateAttemptEventPlayer();
        if (sport === "handball") {
          sendEvent(
            {
              action: "sendEvent",
              value: "edit",
              type: "custom",
              event: updatedAttemptEvent,
            },
            props,
          );
        }
        setAttemptEvent(updatedAttemptEvent);
      }
    }
  }, [selected, attemptsPlayId]);

  useEffect(() => {
    if (!tossWinner) {
      buildTeams(onTossWinnerSelect);
    } else {
      setAttemptsPlayId(getTossWinner(mqtt?.plays)?.playId ?? uuidv1());
      buildTeams(onStarterTeamSelect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, selected, entities]);

  function findPendingAttemptEvent() {
    return mqtt?.plays.findLast((ev) => ev.eventType === "shootOutAttempt" && ev.subType === "");
  }

  function createAttemptEvent() {
    const action = createBaseEvent(currentState, attemptsPlayId);

    action.event["entityId"] = selected.entityId;
    action.event["personId"] = selected.personId;
    action.event.eventType = "shootOutAttempt";
    action.event.subType = "";
    action.event.success = false;

    return action.event;
  }

  function updateAttemptEventPlayer() {
    const newAttemptEvent = { ...attemptEvent };
    newAttemptEvent.status = "updated";
    newAttemptEvent["entityId"] = selected.entityId;
    newAttemptEvent["personId"] = selected.personId;
    return newAttemptEvent;
  }

  function isShootoutStartEventPresent() {
    return !!mqtt?.plays.find((event) => event.eventType === "shootOut" && event.subType === "start");
  }

  function getConfirmDialogText() {
    if (confirmModalFor === "made") {
      return formatMessage({
        id: "shootOut.confirm.made",
        defaultMessage: "Please confirm that a Shootout Goal has been SCORED",
      });
    } else if (confirmModalFor === "retake") {
      return formatMessage({
        id: "shootOut.confirm.retake",
        defaultMessage: "Please confirm that a Shootout Goal has to be RETAKEN",
      });
    } else {
      return formatMessage({
        id: "shootOut.confirm.missed",
        defaultMessage: "Please confirm that a Shootout Goal has been MISSED",
      });
    }
  }

  function buildTeams(onClickHandler) {
    setTeamSelects(
      entities.map((team, index) => {
        const panel = {
          options: { freeText: team.fullNameLatin },
          showOn: [true],
          layout: "team-button",
        };

        return (
          <TypeButton
            {...props}
            key={team.entityId}
            panel={panel}
            team={index}
            style={{
              background: team.primaryColor,
            }}
            onClick={() => onClickHandler(team)}
          />
        );
      }),
    );
  }

  function sendShootoutStartEvent(starterTeam) {
    const action = createBaseEvent(currentState);

    action.event.eventType = "shootOut";
    action.event.subType = "start";
    if (sport === "handball")
      action.event.options = {
        startPossession: starterTeam.entityId,
      };

    sendEvent(action, props);
  }

  function onTossWinnerSelect(team) {
    setAttemptsPlayId(uuidv1());

    sendTossEvent(team.entityId);
    setTossWinner(team.entityId);

    buildTeams(onStarterTeamSelect);
  }

  function onStarterTeamSelect(team) {
    if (!isShootoutStartEventPresent()) {
      sendShootoutStartEvent(team);
    }
    setNextShooterEntity(team.entityId);
  }

  function onEndShootout() {
    const action = createBaseEvent(currentState);

    action.event.eventType = "shootOut";
    action.event.subType = "end";

    sendEvent(action, props);
    sendClockEvent("sport", "period", "end", props);
    sendClockEvent("sport", "period", "confirmed", props);
    updateState("primary", null);
  }

  function updateSelected(person, entityId) {
    setSelected({
      personId: person.personId,
      entityId,
    });
  }

  const onResultClick = (isScored) => {
    setConfirmModalFor(isScored ? "made" : "missed");
  };

  const onRetakeClick = () => {
    setConfirmModalFor("retake");
  };

  function handleDialogConfirm() {
    if (confirmModalFor === "made" || confirmModalFor === "missed") {
      const isScored = confirmModalFor === "made";
      sendAttemptEvent(isScored);
      setAttemptEvent(null);
      if (sport !== "handball") {
        sendDefenceEvent(isScored);
      }
      setSelected({
        personId: "",
        entityId: "",
      });
      setIsPenaltyStroke(false);
    } else if (confirmModalFor === "retake") {
      sendRetakeEvent();
    }
    setConfirmModalFor("");
  }

  function sendTossEvent(winnerId) {
    const id = uuidv1();
    entities.forEach((entity) => {
      const action = createBaseEvent(currentState, id);

      action.event.eventType = "shootOutToss";
      action.event.entityId = entity.entityId;
      action.event.subType = entity.entityId === winnerId ? "won" : "lost";
      action.event.success = entity.entityId === winnerId;

      sendEvent(action, props);
    });
  }

  function sendDefenceEvent(isScored) {
    const action = createBaseEvent(currentState, attemptsPlayId);
    const otherEntity = currentState.entities.find((entity) => entity.entityId !== selected.entityId);

    action.event["entityId"] = otherEntity.entityId;
    action.event["personId"] = activeGks[otherEntity.entityId];
    action.event.eventType = "shootOutDefence";
    action.event.subType = isScored ? "conceded" : "saved";

    sendEvent(action, props);
  }

  function sendAttemptEvent(isScored) {
    const event = { ...attemptEvent };

    const timestamp = new Date();
    event["timestamp"] = timestamp.toISOString();
    event["eventTime"] = getEventTime();
    event["entityId"] = selected.entityId;
    event["personId"] = selected.personId;
    event.eventType = "shootOutAttempt";
    event.subType = isScored ? "made" : "missed";
    event.success = isScored;

    let actionMode;
    if (sport === "handball") {
      actionMode = "edit";
      event.status = "updated";
    } else {
      actionMode = "new";
      event.status = "added";
    }

    if (isPenaltyStroke) {
      event.options = {
        penaltyStroke: true,
      };
    }

    if (sport === "handball") {
      const defenceEntity = entities.find((entity) => {
        return entity.entityId !== selected.entityId;
      });
      event.options = {
        goalKeeperId: activeGks[defenceEntity.entityId],
      };
    }

    sendEvent(
      {
        action: "sendEvent",
        value: actionMode,
        type: "custom",
        event: event,
      },
      props,
    );
  }

  function sendRetakeEvent() {
    const action = createBaseEvent(currentState, attemptsPlayId);

    action.event["entityId"] = selected.entityId;
    action.event["personId"] = selected.personId;
    action.event.eventType = "shootOutAttempt";
    action.event.subType = "retake";
    action.event.success = false;

    sendEvent(action, props);
  }

  const getScoreForTeam = (entityId) => {
    const teamHistory = mqtt?.plays?.filter(
      (event) => event.eventType === "shootOutAttempt" && event.entityId === entityId,
    );

    return teamHistory.reduce((acc, curr) => (acc += curr.success ? 1 : 0), 0);
  };

  const getResultForTeam = (entityId) => {
    // show only the last five
    const teamHistory = mqtt?.plays?.filter(
      (event) =>
        event.eventType === "shootOutAttempt" &&
        event.subType !== "" &&
        event.entityId === entityId &&
        event.subType !== "retake" &&
        !event.options?.penaltyStroke,
    );

    if (teamHistory.length < 10) {
      return Array.from(Array(10)).map((_, idx) => teamHistory[idx] ?? "-");
    }

    return Array.from(Array(Math.floor(teamHistory.length / 5) * 5 + 5)).map((_, idx) => teamHistory[idx] ?? "-");
  };

  const getBibNumberByPersonId = (entityId, personId) => {
    const entity = entities.find((oneEntity) => oneEntity.entityId === entityId);

    if (!entity) {
      return null;
    }

    return entity.persons.find((person) => person.personId === personId)?.bib ?? null;
  };

  const getResultBoxDataForTeam = (entityId) => {
    const results = getResultForTeam(entityId);
    const isActive = (idx) => {
      if (selected?.entityId !== entityId) return false;
      const firstEmptyIdx = results.findIndex((event) => event === "-");
      return idx === firstEmptyIdx;
    };
    return results.map((event, idx) => {
      return {
        status: event === "-" ? (isActive(idx) ? "active" : "waiting") : "handled",
        bib: getBibNumberByPersonId(event.entityId, event.personId),
        isSuccess: event.success,
      };
    });
  };

  if (!tossWinner) {
    return (
      <React.Fragment>
        <h2 className="align-center">
          {formatMessage({
            id: "shootOut.tossWinner",
            defaultMessage: "Select Shootout Toss winner",
          })}
        </h2>

        <div className="team-select-buttons">{teamSelects}</div>
      </React.Fragment>
    );
  }

  if (!nextShooterEntityId && !hasShootOutStarted) {
    return (
      <React.Fragment>
        <h2 className="align-center">
          {formatMessage({
            id: "shootOut.firstShooter",
            defaultMessage: "Select First shooter team",
          })}
        </h2>

        <div className="team-select-buttons">{teamSelects}</div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {confirmModalFor && (
        <ConfirmModal
          {...props}
          text={getConfirmDialogText()}
          onCancel={() => {
            setConfirmModalFor("");
          }}
          onConfirm={handleDialogConfirm}
        />
      )}
      <h2 className="align-center">
        {formatMessage({
          id: "shootOut.competitors",
          defaultMessage: "Select Shootout Competitors",
        })}
      </h2>
      <div className="shootout-main-content">
        <div className="shootout-action-row">
          <h3 className="shootout-team-name">{entities[0].fullNameLatin}</h3>
          <h3 className="shootout-team-name">{entities[1].fullNameLatin}</h3>
        </div>
        <div className="shootout-action-row">
          <div className="shootout-entity-container">
            <PlayerSelect
              entity={entities[0]}
              selectedPlayerId={selected.personId}
              onPlayerClick={(player) => updateSelected(player, entities[0].entityId)}
            />
          </div>
          <div className="shootout-entity-container">
            <PlayerSelect
              entity={entities[1]}
              selectedPlayerId={selected.personId}
              onPlayerClick={(player) => updateSelected(player, entities[1].entityId)}
            />
          </div>
        </div>
        <div className="shootout-running-tally-container">
          <div className="shootout-running-tally">
            <span>{getScoreForTeam(entities[0].entityId)}</span>
            <span>-</span>
            <span>{getScoreForTeam(entities[1].entityId)}</span>
          </div>
        </div>
        <div className="shootout-goalkeepers">
          <div>
            <p>
              {formatMessage({
                id: `shootOut.goalkeeper`,
                defaultMessage: "Goalkeeper",
              })}
              : {entities[1].fullNameLatin}
            </p>
            <select
              onChange={(ev) =>
                setActiveGks({
                  ...activeGks,
                  [entities[1].entityId]: ev.target.value,
                })
              }
              value={activeGks[entities[1].entityId]}
            >
              <option>
                {formatMessage({
                  id: `option.pleaseSelect`,
                  defaultMessage: "Please select",
                })}
              </option>
              {entities[1].persons.map((person) => (
                <option key={person.personId} value={person.personId}>
                  #{person.bib} - {person.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <p>
              {formatMessage({
                id: `shootOut.goalkeeper`,
                defaultMessage: "Goalkeeper",
              })}
              : {entities[0].fullNameLatin}
            </p>
            <select
              onChange={(ev) =>
                setActiveGks({
                  ...activeGks,
                  [entities[0].entityId]: ev.target.value,
                })
              }
              value={activeGks[entities[0].entityId]}
            >
              <option>
                {formatMessage({
                  id: `option.pleaseSelect`,
                  defaultMessage: "Please select",
                })}
              </option>
              {entities[0].persons.map((person) => (
                <option key={person.personId} value={person.personId}>
                  #{person.bib} - {person.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="shootout-action-row">
          <div className="shootout-result-buttons">
            <ResultBox data={getResultBoxDataForTeam(entities[0].entityId)} />
            {selected?.entityId === entities[0].entityId && activeGks[entities[1].entityId] && (
              <>
                <div>
                  <button
                    className="shootout-result-button shootout-result-button--success"
                    onClick={() => onResultClick(true)}
                  >
                    {formatMessage({
                      id: `shootOut.${sport}.made`,
                      defaultMessage: "Goal",
                    })}
                  </button>
                  <button
                    className="shootout-result-button shootout-result-button--danger"
                    onClick={() => onResultClick(false)}
                  >
                    {formatMessage({
                      id: `shootOut.${sport}.missed`,
                      defaultMessage: "Missed",
                    })}
                  </button>
                  {sport !== "handball" && (
                    <button
                      className="shootout-result-button shootout-result-button--retake"
                      onClick={() => onRetakeClick()}
                    >
                      {formatMessage({
                        id: `shootOut.${sport}.retake`,
                        defaultMessage: "RETAKE",
                      })}
                    </button>
                  )}
                  {sport === "hockey" && (
                    <Checkbox
                      checked={isPenaltyStroke}
                      onChange={(e) => setIsPenaltyStroke(e.target.checked)}
                      label={formatMessage({
                        id: `shootOut.penaltyStroke`,
                        defaultMessage: "Penalty Stroke",
                      })}
                      style={{ marginTop: "14px" }}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          <div className="shootout-result-buttons">
            <ResultBox data={getResultBoxDataForTeam(entities[1].entityId)} />
            {selected?.entityId === entities[1].entityId && activeGks[entities[0].entityId] && (
              <>
                <div>
                  <button
                    className="shootout-result-button shootout-result-button--success"
                    onClick={() => onResultClick(true)}
                  >
                    {formatMessage({
                      id: `shootOut.${sport}.made`,
                      defaultMessage: "Goal",
                    })}
                  </button>
                  <button
                    className="shootout-result-button shootout-result-button--danger"
                    onClick={() => onResultClick(false)}
                  >
                    {formatMessage({
                      id: `shootOut.${sport}.missed`,
                      defaultMessage: "Missed",
                    })}
                  </button>
                  {sport !== "handball" && (
                    <button
                      className="shootout-result-button shootout-result-button--retake"
                      onClick={() => onRetakeClick()}
                    >
                      {formatMessage({
                        id: `shootOut.${sport}.retake`,
                        defaultMessage: "RETAKE",
                      })}
                    </button>
                  )}
                  {sport === "hockey" && (
                    <Checkbox
                      checked={isPenaltyStroke}
                      onChange={(e) => setIsPenaltyStroke(e.target.checked)}
                      label={formatMessage({
                        id: `shootOut.penaltyStroke`,
                        defaultMessage: "Penalty Stroke",
                      })}
                      style={{ marginTop: "14px" }}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="shootout-end-container">
          {sport === "hockey" && (
            <button
              className="shootout-end-btn"
              onClick={() => {
                updateState("secondary", "video-review-type-select");
              }}
            >
              {formatMessage({
                id: "videoReview",
                defaultMessage: "Video Review",
              })}
            </button>
          )}
          <button className="shootout-end-btn" onClick={onEndShootout}>
            {formatMessage({
              id: `shootOut.end`,
              defaultMessage: "End shoot out",
            })}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default injectIntl(Shootout);
