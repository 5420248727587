import {
  BlueCardButton,
  Clock,
  Court,
  EventDelete,
  EventInsert,
  FloatingPanel,
  InsertClock,
  PassivePlayButton,
  Penalty7MeterButton,
  RedCardButton,
  SuspensionButton,
  SwitchTeamsButton,
  TimeoutButton,
  VideoReviewButton,
  VideoReviewEndButton,
  YellowCardButton,
} from "./includes/Base/";
import {
  InsertBlockPlayerSelect,
  InsertChildPlayerSameTeam,
  ModifyDefenceGoalkeeperSelect,
  InsertSanctionsType,
  InsertSuspensionType,
  ModifyEntitySelect,
  ModifyGoalkeeperSelect,
  ModifyPenaltyPlayerSelect,
  ModifyPlayerSelect,
  ModifySanctionsType,
  ModifyShotAttackType,
  ModifyShotLocation,
  ModifyShotMadeToMissed,
  ModifyShotMissedOutcomeType,
  ModifyShotMissedToMade,
  ModifyTechnicalFaultType,
  ModifyThrowOff,
  ModifyTimeout,
  ModifyShootoutAttemptOutcome,
} from "./includes/Modify";
import {
  active7mPenaltyFailureReason,
  modify7mPenaltyFailureReason,
} from "./includes/ModifyDropdown/Modify7mPenaltyFailureReason";
import { activeGoalAttackType, modifyGoalAttackType } from "./includes/ModifyDropdown/ModifyGoalAttackType";
import { activeGoalFailureReason, modifyGoalFailureReason } from "./includes/ModifyDropdown/ModifyGoalFailureReason";
import { activePassivePlayTeam, modifyPassivePlayTeam } from "./includes/ModifyDropdown/ModifyPassivePlayTeam";
import { activeTimeOutTeam, modifyTimeOutTeam } from "./includes/ModifyDropdown/ModifyTimeOutTeam";
import {
  AssistPlayerSelect,
  DefenceGoalkeeperSelect,
  ThrowOff,
  PenaltyChangeGoalKeeper,
  PenaltyFoulDrawnPlayer,
  PenaltyFoulPlayer,
  PenaltyShotFailureReason,
  PenaltyShotOutcomeSelect,
  PenaltyShotPlayer,
  PenaltySuspensionPlayer,
  PunishmentPlayerSelect,
  SanctionsType,
  Shootout,
  ShotAttackTypeSelect,
  ShotBlockPlayerSelect,
  ShotMissedOutcomeTypeSelect,
  ShotPlayerSelect,
  StealPlayerSelect,
  SuspensionType,
  SuspensionPlayerSelect,
  TechnicalFaultPlayer,
  TechnicalFaultType,
  Timeout,
  VideoReviewTypeSelect,
  VideoReviewTeamSelect,
  VideoReviewOutcomeSelect,
  PenaltySuspension,
  GoalkeeperChangeSelect,
} from "./includes/Panels";

const HandballWorkflow = {
  displayEventLog: true,
  sport: "handball",
  panels: [
    {
      name: "main-area",
      component: "StackingPanel",
      showOn: [true],
      layout: "column",
      children: [
        {
          name: "top-panel",
          component: "StackingPanel",
          layout: "fill-content",
          showOn: [true],
          children: [
            {
              name: "team-0-team-select",
              component: "Teams",
              showOn: [true],
              mode: "visible",
              showPossessionIcon: true,
              possessionIcon: "arrow-right icon-large",
              showSinBin: true,
              showArrow: false,
              showStats: false,
            },
            {
              name: "clock-and-buttons",
              component: "StackingPanel",
              showOn: [true],
              layout: "column fill-parent clock-and-buttons reverse-colors",
              children: [
                Clock,
                {
                  name: "match-buttons",
                  component: "ButtonPanel",
                  layout: "match-buttons-stackable",
                  mode: "visible",
                  showOn: [true],
                  children: [VideoReviewButton, VideoReviewEndButton, SwitchTeamsButton, TimeoutButton],
                },
              ],
            },
            {
              name: "team-1-team-select",
              component: "Teams",
              showOn: [true],
              mode: "visible",
              showPossessionIcon: true,
              possessionIcon: "arrow-left icon-large",
              showSinBin: true,
              showArrow: false,
              showStats: false,
            },
          ],
        },
        {
          name: "play-area",
          component: "StackingPanel",
          showOn: [true],
          layout: "fill-parent",
          children: [
            {
              name: "players-0",
              component: "StackingPanel",
              showOn: [true],
              layout: "column handball-players-container",
              children: [
                {
                  name: "team-0-player-select",
                  component: "Players",
                  showOn: [true],
                  mode: "visible",
                },
                {
                  name: "team-0-action-buttons",
                  component: "TeamButtonGroup",
                  mode: "visible",
                  showOn: [true],
                  text: "events",
                  options: { team: 0 },
                  children: [
                    SuspensionButton,
                    Penalty7MeterButton,
                    YellowCardButton,
                    RedCardButton,
                    BlueCardButton,
                    PassivePlayButton,
                  ],
                },
              ],
            },
            {
              name: "middle-play-area",
              component: "StackingPanel",
              showOn: [true],
              layout: "column fill-content",
              children: [
                Court,
                {
                  name: "suspended-players",
                  component: "SuspendedPlayers",
                  mode: "visible",
                  showOn: [true],
                },
              ],
            },
            {
              name: "players-1",
              component: "StackingPanel",
              showOn: [true],
              layout: "column handball-players-container",
              children: [
                {
                  name: "team-1-player-select",
                  component: "Players",
                  showOn: [true],
                  mode: "visible",
                },
                {
                  name: "team-1-action-buttons",
                  component: "TeamButtonGroup",
                  mode: "visible",
                  showOn: [true],
                  text: "events",
                  options: { team: 1 },
                  children: [
                    SuspensionButton,
                    Penalty7MeterButton,
                    YellowCardButton,
                    RedCardButton,
                    BlueCardButton,
                    PassivePlayButton,
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    EventInsert,
    FloatingPanel,
    InsertClock,
    ThrowOff,
    Timeout,
    PenaltyFoulPlayer,
    ShotAttackTypeSelect,
    ShotBlockPlayerSelect,
    ShotPlayerSelect,
    PenaltyFoulDrawnPlayer,
    PenaltyShotPlayer,
    PenaltyShotOutcomeSelect,
    PenaltyShotFailureReason,
    PenaltyChangeGoalKeeper,
    PenaltySuspensionPlayer,
    AssistPlayerSelect,
    DefenceGoalkeeperSelect,
    ShotMissedOutcomeTypeSelect,
    TechnicalFaultPlayer,
    TechnicalFaultType,
    StealPlayerSelect,
    SanctionsType,
    SuspensionType,
    SuspensionPlayerSelect,
    PunishmentPlayerSelect,
    Shootout,
    VideoReviewTypeSelect,
    VideoReviewTeamSelect,
    VideoReviewOutcomeSelect,
    PenaltySuspension,
    GoalkeeperChangeSelect,
    {
      name: "edit",
      component: "EditPanel",
      layout: "edit-base",
      mode: "visible",
      showOn: true,
    },
    EventDelete,
  ],
  modify: {
    types: [
      {
        name: "goal",
        eventTypes: ["goal"],
        success: true,
        editable: [
          { type: "personId", text: "edit.shot.person", panel: "player" },
          {
            type: "success",
            text: "edit.shot.success.missed",
            panel: "shot-success-made",
          },
          {
            type: "attackType",
            text: "edit.shot.attackType",
            panel: "shot-attack-type",
          },
          {
            type: "goalkeeper",
            text: "edit.shot.goalkeeper",
            panel: "modify-defence-goalkeeper",
          },
          {
            type: "location",
            text: "edit.shot.location-modify",
            panel: "modify-shot-location",
          },
        ],
        delete: true,
        delete_all: true,
        child_inserts: [
          {
            type: "assist",
            text: "insert.child.assist",
            panel: "child-insert-same",
          },
        ],
      },
      {
        name: "goal_attempt",
        eventTypes: ["goal"],
        success: false,
        editable: [
          { type: "personId", text: "edit.shot.person", panel: "player" },
          {
            type: "success",
            text: "edit.shot.success.made",
            panel: "shot-success-missed",
          },
          {
            type: "attackType",
            text: "edit.shot.attackType",
            panel: "shot-attack-type",
          },
          {
            type: "shotOutcomeType",
            text: "edit.shot.outcomeType",
            panel: "shot-missed-outcome-type",
          },
          {
            type: "goalkeeper",
            text: "edit.shot.goalkeeper",
            panel: "modify-defence-goalkeeper",
          },
          {
            type: "location",
            text: "edit.shot.location-modify",
            panel: "modify-shot-location",
          },
        ],
        child_inserts: [
          {
            type: "block",
            text: "insert.child.blockPlayer",
            panel: "insert-block-player",
            option: {failureReason: "SHOT_BLOCKED"},
          },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "assist",
        eventTypes: ["assist"],
        success: null,
        editable: [{ type: "personId", text: "edit.assist.person", panel: "player" }],
        delete: true,
        delete_all: false,
      },
      {
        name: "block",
        eventTypes: ["block"],
        success: null,
        editable: [{ type: "personId", text: "edit.block.person", panel: "player" }],
        delete: true,
        delete_all: false,
      },
      {
        name: "sevenMetrePenaltyCaused",
        eventTypes: ["sevenMetrePenalty"],
        subType: { subType: "caused", operator: "is" },
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.sevenMetrePenalty.caused",
            panel: "penalty-player",
          },
        ],
        delete: true,
        delete_all: true,
        child_inserts: [
          {
            type: "no-sanction",
            text: "insert.child.no-sanction",
            panel: "sanctions-type-insert",
            allowedTypes: ["suspension", "yellowCard", "redCard", "blueCard"],
            allowedSubTypes: ["caused"],
          },
        ],
      },
      {
        name: "sevenMetrePenaltyAwarded",
        eventTypes: ["sevenMetrePenalty"],
        subType: { subType: "awarded", operator: "is" },
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.sevenMetrePenalty.awarded",
            panel: "player",
          },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "sevenMetrePenaltyShotMade",
        eventTypes: ["sevenMetrePenalty"],
        subType: { subType: "shot", operator: "is" },
        success: true,
        editable: [
          {
            type: "personId",
            text: "edit.sevenMetrePenalty.shot",
            panel: "player",
          },
          {
            type: "success",
            text: "edit.shot.success.missed",
            panel: "shot-success-made",
          },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "sevenMetrePenaltyShotMissed",
        eventTypes: ["sevenMetrePenalty"],
        subType: { subType: "shot", operator: "is" },
        success: false,
        editable: [
          {
            type: "personId",
            text: "edit.sevenMetrePenalty.shot",
            panel: "player",
          },
          {
            type: "success",
            text: "edit.shot.success.made",
            panel: "shot-success-missed",
          },
          {
            type: "shotOutcomeType",
            text: "edit.shot.outcomeType",
            panel: "shot-missed-outcome-type",
          },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "technicalFault",
        eventTypes: ["technicalFault"],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.technicalFault.person",
            panel: "player",
          },
          {
            type: "technicalFaultType",
            text: "edit.technicalFault.type",
            panel: "technical-fault-type",
          },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "steal",
        eventTypes: ["steal"],
        success: null,
        editable: [{ type: "personId", text: "edit.steal.person", panel: "player" }],
        delete: true,
        delete_all: true,
      },
      {
        name: "turnover",
        eventTypes: ["turnover"],
        success: null,
        editable: [{ type: "personId", text: "edit.turnover.person", panel: "player" }],
        delete: true,
        delete_all: true,
      },
      {
        name: "substitution",
        eventTypes: ["substitution"],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.substitution.player",
            panel: "player",
          },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "suspension",
        eventTypes: ["suspension", "yellowCard", "redCard", "blueCard"],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.foul.person",
            panel: "penalty-player",
          },
          {
            type: "suspension",
            text: "edit.sanctions.type",
            panel: "sanctions-type",
          },
        ],
        delete: true,
        delete_all: false,
        child_inserts: [
          {
            type: "suspension",
            text: "insert.child.no-suspension",
            allowedEventTypes: ["yellowCard"],
            panel: "suspension-type-insert",
          },
        ],
      },
      {
        name: "timeOut",
        eventTypes: ["timeOut"],
        success: null,
        editable: [{ type: "timeOut", text: "edit.timeout", panel: "modify-timeout" }],
        delete: true,
        delete_all: true,
      },
      {
        name: "videoReview",
        eventTypes: ["videoReview"],
        success: null,
        editable: [{ type: "delete_only", text: null, panel: null }],
        delete: true,
        delete_all: true,
      },
      {
        name: "passivePlay",
        eventTypes: ["passivePlay"],
        success: null,
        editable: [{ type: "entityId", text: "edit.entity", panel: "entity" }],
        delete: true,
        delete_all: false,
        confirm_possession: false,
      },
      {
        name: "goalKeeperChange",
        eventTypes: ["goalKeeperChange"],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.goalkeeper.change",
            panel: "goalkeeper-change-select",
          },
        ],
        delete: true,
        delete_all: false,
      },
      {
        name: "throwOff",
        eventTypes: ["throwOff"],
        success: null,
        editable: [
          {
            type: "throwOff",
            text: "edit.throwOff.team",
            panel: "modify-throw-off",
          },
        ],
        delete: false,
        delete_all: false,
      },
      {
        name: "shootOutAttempt",
        eventTypes: ["shootOutAttempt"],
        success: null,
        editable: [
          {
            type: "success",
            text: "edit.shootout.success",
            panel: "shootout-attempt-outcome",
          },
          { type: "personId", text: "edit.shootout.person", panel: "player" },
        ],
        delete: false,
      },
      {
        name: "goalKeeperId",
        eventTypes: ["goalKeeperId"],
        success: null,
        option: "goalKeeperId",
        editable: [
          {
            type: "goalkeeper",
            text: "edit.shot.goalkeeper",
            panel: "modify-defence-goalkeeper",
          },
        ],
        delete: true,
        delete_all: true,
      },
    ],
    panels: [
      InsertBlockPlayerSelect,
      InsertChildPlayerSameTeam,
      ModifyDefenceGoalkeeperSelect,
      InsertSanctionsType,
      InsertSuspensionType,
      ModifyGoalkeeperSelect,
      ModifyPenaltyPlayerSelect,
      ModifyPlayerSelect,
      ModifySanctionsType,
      ModifyShotAttackType,
      ModifyShotLocation,
      ModifyShotMadeToMissed,
      ModifyShotMissedOutcomeType,
      ModifyShotMissedToMade,
      ModifyTechnicalFaultType,
      ModifyThrowOff,
      ModifyTimeout,
      ModifyEntitySelect,
      ModifyShootoutAttemptOutcome,
    ],
  },
  modifyDropdown: {
    types: [
      {
        name: "goalSuccess",
        eventTypes: ["goal"],
        success: true,
        edit: {
          type: "customFn",
          customFn: modifyGoalAttackType,
          activeFn: activeGoalAttackType,
          options: {
            FAST_BREAK: "edit.dropdown.goal.attacktype.fastBreak",
            BREAK_THROUGH: "edit.dropdown.goal.attacktype.breakThrough",
            PIVOT: "edit.dropdown.goal.attacktype.pivot",
            DIRECT_FREE_THROW: "edit.dropdown.goal.attacktype.directFreeThrow",
            none: "edit.dropdown.goal.attacktype.none",
          },
        },
      },
      {
        name: "goalFailure",
        eventTypes: ["goal"],
        success: false,
        edit: [
          {
            type: "customFn",
            customFn: modifyGoalFailureReason,
            activeFn: activeGoalFailureReason,
            options: {
              OFF_TARGET: "edit.dropdown.goal.failure.offTarget",
              POST: "edit.dropdown.goal.failure.post",
              BAR: "edit.dropdown.goal.failure.bar",
              SAVE: "edit.dropdown.goal.failure.save",
              SHOT_BLOCKED: "edit.dropdown.goal.failure.block",
            },
          },
          {
            type: "customFn",
            customFn: modifyGoalAttackType,
            activeFn: activeGoalAttackType,
            options: {
              FAST_BREAK: "edit.dropdown.goal.attacktype.fastBreak",
              BREAK_THROUGH: "edit.dropdown.goal.attacktype.breakThrough",
              PIVOT: "edit.dropdown.goal.attacktype.pivot",
              DIRECT_FREE_THROW: "edit.dropdown.goal.attacktype.directFreeThrow",
              none: "edit.dropdown.goal.attacktype.none",
            },
          },
        ],
      },
      {
        name: "sevenMetrePenalty",
        eventTypes: ["sevenMetrePenalty"],
        success: false,
        edit: {
          type: "customFn",
          customFn: modify7mPenaltyFailureReason,
          activeFn: active7mPenaltyFailureReason,
          options: {
            OFF_TARGET: "edit.dropdown.7mPenalty.failure.offTarget",
            SAVE: "edit.dropdown.7mPenalty.failure.save",
            POST: "edit.dropdown.7mPenalty.failure.post",
            BAR: "edit.dropdown.7mPenalty.failure.bar",
          },
        },
      },
      {
        name: "techFault",
        eventTypes: ["technicalFault"],
        edit: {
          type: "subType",
          options: {
            ballFault: "edit.dropdown.techfault.subtype.ball",
            ruleFault: "edit.dropdown.techfault.subtype.rule",
          },
        },
      },
      {
        name: "suspension",
        eventTypes: ["suspension"],
        edit: {
          type: "subType",
          options: {
            twoMinutes: "edit.dropdown.suspension.subtype.twoMinutes",
            fourMinutes: "edit.dropdown.suspension.subtype.fourMinutes",
          },
        },
      },
      {
        name: "assist",
        eventTypes: ["assist"],
        edit: {
          type: "delete",
          options: {
            false: "edit.dropdown.assist.delete.no",
            true: "edit.dropdown.assist.delete.yes",
          },
        },
      },
      {
        name: "timeout",
        eventTypes: ["timeOut"],
        edit: {
          type: "customFn",
          customFn: modifyTimeOutTeam,
          activeFn: activeTimeOutTeam,
          options: {
            home: "edit.dropdown.timeOut.team.home",
            away: "edit.dropdown.timeOut.team.away",
          },
        },
      },
      {
        name: "passivePlay",
        eventTypes: ["passivePlay"],
        edit: {
          type: "customFn",
          customFn: modifyPassivePlayTeam,
          activeFn: activePassivePlayTeam,
          options: {
            home: "edit.dropdown.passivePlay.team.home",
            away: "edit.dropdown.passivePlay.team.away",
          },
        },
      },
    ],
  },
};

export default HandballWorkflow;
