import Paho from "paho-mqtt";
import { v1 as uuidv1 } from "uuid";
import { Tracing } from "../../../App.tracing";

import defaultSetup from "../../../Config/defaultSetupConfiguration";

export function managePlayers(currentState, fixtureId, updateState) {
  let config = localStorage.getItem(fixtureId + "-teams")
    ? JSON.parse(localStorage.getItem(fixtureId + "-teams"))
    : null;

  let tempEntities = [];

  (currentState?.entities ?? currentState.mqtt.entities).forEach((team) => {
    // it means it's the global entity (with officials etc) and should be skipped here
    if (team.entityId === "FIXTURE") return;
    const storedTeam = config.find(
      (entity) => entity.entityId === team.entityId
    );
    // sometimes erroneously Streaming API has more entities assigned to this fixture, this is a temporary fix for it to stop app from crashing
    if (!storedTeam) return;

    storedTeam.persons = [];
    Object.keys(team.persons).forEach((personId) => {
      storedTeam.persons.push({
        ...{
          personId: personId,
          active: team.persons[personId].starter ? true : false,
        },
        ...team.persons[personId],
      });
    });
    tempEntities.push(storedTeam);
  });
  updateState("entities", tempEntities);

  const mqttState = currentState.mqtt;
  mqttState.entitiesProcessed = true;
  updateState("mqtt", mqttState);
}

function applySportSpecificLogicOnMsg(msg, sport, entity) {
  if (sport === "handball") {
    if (msg.status === "added") {
      if (msg?.options?.position && msg.options.position === "G") {
        const goalkeepers = entity.persons.filter(
          (person) => person.position === "G" && person.starter
        );
        msg.options.starter = goalkeepers.count === 0;
      } else {
        msg.options.starter = true;
        msg.options.active = true;
      }
    }

    if (msg.status === "deleted") {
      msg.options.starter = false;
      msg.options.active = false;
    }
  }

  return msg;
}

export function sendPlayer(person, entity, mqtt, fixtureId, status, sport) {
  let msg = {};

  msg.class = "setup";
  msg.eventType = "person";
  msg.entityId = entity.entityId;
  msg.personId = person.personId;
  msg.eventId = uuidv1();
  msg.options = {
    captain: person.captain,
    bib: person.bib,
    name: person.nameFullLocal || person.fullNameLatin,
    starter: person.starter,
  };
  msg.status = status;

  if (person.position) {
    msg.options.position = person.position;
  }

  applySportSpecificLogicOnMsg(msg, sport, entity);

  let timestamp = new Date();
  msg.timestamp = timestamp.toISOString();
  msg.eventTime = timestamp.toISOString();

  let messageContent = JSON.stringify({
    type: "event",
    fixtureId: fixtureId,
    data: msg,
    clientType: defaultSetup.options.clientType,
  });

  var message = new Paho.Message(messageContent);
  message.destinationName = mqtt.topic;
  try {
    mqtt.client.send(message);
  } catch (err) {
    Tracing.capture(err);
    console.error("Send Error", err);
  }
}

export function sendStaffRole(person, entity, mqtt, fixtureId, status) {
  const timestamp = new Date();
  const msg = {
    class: "setup",
    eventType: "role",
    subType: "entity",
    entityId: entity.entityId,
    personId: person.personId,
    eventId: uuidv1(),
    options: {
      name: person.name,
      number: person.number,
      position: person.role,
    },
    status,
    timestamp: timestamp.toISOString(),
    eventTime: timestamp.toISOString(),
  };

  const messageContent = JSON.stringify({
    type: "event",
    fixtureId: fixtureId,
    data: msg,
    clientType: defaultSetup.options.clientType,
  });

  const message = new Paho.Message(messageContent);
  message.destinationName = mqtt.topic;
  try {
    mqtt.client.send(message);
  } catch (err) {
    Tracing.capture(err);
    console.error("Send Error", err);
  }
}

export function sendAttendance(attendance, mqtt, fixtureId, fixtureProfile) {
  const timestamp = new Date();
  const msg = {
    class: "setup",
    eventType: "fixture",
    subType: "post",
    options: {
      attendance,
      numberOfPeriods: fixtureProfile.numberOfPeriods,
      periodLength: fixtureProfile.periodLength,
    },
    eventId: uuidv1(),
    timestamp: timestamp.toISOString(),
    eventTime: timestamp.toISOString(),
  };
  const messageContent = JSON.stringify({
    type: "event",
    fixtureId: fixtureId,
    data: msg,
    clientType: defaultSetup.options.clientType,
  });
  const message = new Paho.Message(messageContent);
  message.destinationName = mqtt.topic;
  try {
    mqtt.client.send(message);
  } catch (err) {
    Tracing.capture(err);
    console.error("Send Error", err);
  }
}

export function sendFixtureRole(person, mqtt, fixtureId, status) {
  const timestamp = new Date();
  const msg = {
    class: "setup",
    eventType: "role",
    subType: "fixture",
    personId: person.personId,
    entityId: "FIXTURE",
    eventId: uuidv1(),
    options: {
      name: person.name,
      position: person.role,
      number: person.number,
    },
    status,
    timestamp: timestamp.toISOString(),
    eventTime: timestamp.toISOString(),
  };

  const messageContent = JSON.stringify({
    type: "event",
    fixtureId: fixtureId,
    data: msg,
    clientType: defaultSetup.options.clientType,
  });

  const message = new Paho.Message(messageContent);
  message.destinationName = mqtt.topic;
  try {
    mqtt.client.send(message);
  } catch (err) {
    Tracing.capture(err);
    console.error("Send Error", err);
  }
}

export function clearDesiredPlayingState(fixtureId) {
  const rawTeams = localStorage.getItem(fixtureId + "-teams");

  let storedTeams = !!rawTeams ? JSON.parse(rawTeams) : null;

  if (!storedTeams) {
    return;
  }

  const withoutDesiredState = storedTeams.map((team) => ({
    ...team,
    persons: team.persons.map((person) => ({
      ...person,
      desiredPlayingState: undefined,
    })),
  }));

  localStorage.setItem(
    `${fixtureId}-teams`,
    JSON.stringify(withoutDesiredState)
  );
}
