import { useEffect, useRef, useState } from "react";
import "./ClockEditorSegment.scss";

const inputValidKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

const ClockEditorSegment = (props) => {
  const { value, rollover, onChange } = props;
  const [caretPos, setCaretPos] = useState(0);
  const inputRef = useRef();

  const handleValueChange = (delta) => {
    const newValue = parseInt(value) + delta;
    if (rollover) {
      if (newValue < rollover[0]) {
        onChange(rollover[1] - 1, -1);
      } else if (newValue > rollover[1]) {
        onChange(rollover[0] + 1, 1);
      } else {
        onChange(newValue, 0);
      }
    } else {
      onChange(newValue);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.selectionStart = caretPos;
      inputRef.current.selectionEnd = caretPos;
    }
  }, [inputRef, value]);

  return (
    <div className="clock-editor-segment-root">
      <input
        value={value}
        inputMode="numeric"
        type="text"
        className="clock-editor-segment-time"
        onClick={(event) => {
          setCaretPos(event.target.selectionStart);
        }}
        onKeyDown={(event) => {
          event.preventDefault();
          if (inputValidKeys.includes(event.key) && event.target.selectionStart < 2) {
            let asArray = event.target.value.split("");
            asArray.splice(event.target.selectionStart, 1, event.key);
            const newStr = asArray.join("");
            let newValue;
            if (rollover) {
              newValue = Math.max(rollover[0], Math.min(rollover[1] - 1, parseInt(newStr)));
            } else {
              newValue = parseInt(newStr);
            }
            setCaretPos(event.target.selectionStart + 1);
            event.target.selectionStart += 1;
            onChange(newValue);
          }
        }}
        ref={inputRef}
      />
      <div className="clock-editor-segment-buttons">
        <div
          className="clock-editor-segment-button"
          onClick={() => {
            handleValueChange(1);
          }}
        >
          <i className="fas fa-caret-up" />
        </div>
        <div
          className="clock-editor-segment-button"
          onClick={() => {
            handleValueChange(-1);
          }}
        >
          <i className="fas fa-caret-down" />
        </div>
      </div>
    </div>
  );
};

export default ClockEditorSegment;
