import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { injectIntl } from "react-intl";
import { FixtureDetailsContext } from "../../Config/FixtureDetails";
import GridTableDisplay from "../Base/Shared/GridTableDisplay";
import { sendFixtureRole } from "../Base/Utilities/ManagePlayers";
import TeamSetupCheckbox from "../TeamSetup/Team/TeamSetupCheckbox";
import Logger from "../Base/Utilities/Logger";

const logger = Logger.getInstance();

const NewOfficials = ({ currentState, intl: { formatMessage } }) => {
  const { fixtureRoles } = useContext(FixtureDetailsContext);
  const [officialsData, setOfficialsData] = useState([]);

  const fixtureEntity = currentState.mqtt?.entities.find((entity) => entity.entityId === "FIXTURE");

  const columns = useMemo(() => {
    return [
      formatMessage({ id: "person.playing", defaultMessage: "Active" }),
      formatMessage({
        id: "person.role",
        defaultMessage: "Role",
      }),
      formatMessage({
        id: "person.name",
        defaultMessage: "Name",
      }),
    ];
  }, [formatMessage]);

  const getValidRoleNumberForPerson = (personData) => {
    const personsWithSameRole = officialsData.filter((o) => o.role === personData.role && o.personId !== null);
    const existingNumbers = personsWithSameRole.map((o) => o.number).sort((a, b) => a - b);
    const newPersonNumberGuess = existingNumbers.findIndex((o, index) => o !== index + 1);
    return newPersonNumberGuess !== -1 ? newPersonNumberGuess + 1 : personsWithSameRole.length + 1;
  };

  useEffect(() => {
    const newOfficialsObj = {};
    if (fixtureRoles?.fixture) {
      fixtureRoles.fixture.forEach(
        (official) =>
          (newOfficialsObj[`${official.personId}_${official.role}`] = {
            role: official.role,
            personId: official.personId,
            number: official.roleNumber,
            name: official.nameFullLocal || official.nameFullLatin,
            active: false,
          }),
      );
    }

    if (fixtureEntity?.roles) {
      Object.entries(fixtureEntity.roles).forEach(([role, persons]) => {
        Object.keys(persons).forEach((personId) => {
          if (!newOfficialsObj[`${personId}_${role}`]) {
            const msg = `Person ${personId} of role ${role} is in Streaming API, but not the Details call`;
            console.error(msg);
            logger.log(msg);
          } else newOfficialsObj[`${personId}_${role}`].active = true;
        });
      });
    }
    setOfficialsData(Object.values(newOfficialsObj));
  }, [fixtureRoles, fixtureEntity]);

  const handleOfficialActiveChange = useCallback(
    (e, officialIndex) => {
      const newOfficialPersonData = {
        ...officialsData[officialIndex],
      };
      newOfficialPersonData.active = e.target.checked;
      newOfficialPersonData.number = getValidRoleNumberForPerson(newOfficialPersonData);
      const status = newOfficialPersonData.active ? "added" : "deleted";

      sendFixtureRole(newOfficialPersonData, currentState.mqtt, currentState.fixtureId, status);

      const newOfficialsData = [...officialsData];
      newOfficialsData[officialIndex] = newOfficialPersonData;
      setOfficialsData(newOfficialsData);
    },
    [officialsData],
  );

  const rows = useMemo(() => {
    return officialsData.map((official, index) => [
      <TeamSetupCheckbox
        checked={official.active}
        onChange={(e) => handleOfficialActiveChange(e, index)}
        enabled={official.personId !== null && official.role !== null}
      />,
      formatMessage({
        id: `role.${official.role.toLowerCase()}`,
        defaultMessage: `${official.role}`,
      }),
      official.name,
    ]);
  }, [officialsData]);

  return (
    <div className="game-details--game-officials">
      <div className="game-details--section-header-no-border">
        {formatMessage({
          id: "details.officials",
          defaultMessage: "Officials",
        })}
      </div>
      <div className="officials--container">
        <GridTableDisplay
          columns={columns}
          rows={rows}
          headerClass="officials--table-header"
          rowCellClass="officials-cell"
          tableKeyPrefix="officials-roster"
        />
      </div>
    </div>
  );
};

export default injectIntl(NewOfficials);
