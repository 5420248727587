export var ModifyShotLocation = {
  name: "shot-location",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "edit.shot.location",
  showOn: [{ state: "editState", value: "modify-shot-location", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    {
      name: "location-editor",
      component: "LocationEditor",
      layout: "sub-panel",
      mode: "visible",
      showOn: [true],
      actionGroups: [
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "edit",
            },
            {
              action: "nextState",
              value: null,
              type: "editState",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
