export const AssistPlayerSelect = {
  name: "goal-assist",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "assist.select-player",
  showOn: [{ state: "primary", value: "goal-assist", operator: "is" }],
  allowBack: {
    state: "primary",
    value: "shot-player",
    event: "shot",
    save: false,
  },
  allowFlag: true,
  allowCancel: false,
  showDetails: { events: ["shot"] },
  children: [
    {
      name: "assist-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: true,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: true,
        showBench: false,
        showCoach: false,
        excludePlayer: true,
        excludeFrom: "shot",
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "assist", type: "eventType" },
            {
              action: "clearData",
              value: ["options", "subType", "scores", "success", "x", "y"],
              type: "main",
            },
            { action: "sendEvent", value: "new", type: "main" },
          ],
        },
        {
          actions: [
            { action: "copyEvent", value: "shot", type: "from" },
            { action: "nextState", value: "defence-goalkeeper", type: "primary" },
          ],
          conditions: [{ item: "isInsertMode" }],
        },
        {
          actions: [{ action: "nextState", value: null, type: "primary" }],
        },
      ],
    },
    {
      name: "match-buttons",
      component: "ButtonPanel",
      layout: "match-buttons-stackable centered",
      mode: "visible",
      showOn: [true],
      children: [
        {
          name: "confirm-later-assist",
          component: "TypeButton",
          layout: "match-button",
          mode: "enable",
          text: "confirmLater",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                { action: "saveData", value: "assist", type: "eventType" },
                {
                  action: "clearData",
                  value: ["options", "subType", "scores", "success", "personId", "x", "y"],
                  type: "main",
                },
                { action: "copyData", type: "shot", value: "entityId" },
                { action: "saveData", type: "flagged", value: true },
                { action: "sendEvent", value: "new", type: "main" },
              ],
              conditions: [],
            },
            {
              actions: [
                { action: "copyEvent", value: "shot", type: "from" },
                { action: "nextState", value: "defence-goalkeeper", type: "primary" },
              ],
              conditions: [{ item: "isInsertMode" }],
            },
            {
              actions: [{ action: "nextState", value: null, type: "primary" }],
            },
          ],
          startEvent: true,
          startPlay: false,
        },
        {
          name: "no-assist",
          component: "TypeButton",
          layout: "match-button",
          mode: "enable",
          text: "assist.none",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                { action: "copyEvent", value: "shot", type: "from" },
                { action: "nextState", value: "defence-goalkeeper", type: "primary" },
              ],
              conditions: [{ item: "isInsertMode" }],
            },
            {
              actions: [{ action: "nextState", value: null, type: "primary" }],
              conditions: [],
            },
          ],
          startEvent: false,
          startPlay: false,
        },
      ],
    },
  ],
};
