import React, { useMemo, useEffect, useState } from "react";
import { injectIntl } from "react-intl";

import EditListItem from "../EditListItem/EditListItem";
import InsertChildItem from "../InsertChildItem/InsertChildItem";
import { v1 as uuidv1 } from "uuid";

const EditList = (props) => {
  const { intl, eventStore, currentState, workFlow, updateState, setShowList } = props;
  const { formatMessage } = intl;
  const [doOmitShowList, setDoOmitShowList] = useState(false);

  const playEvents = useMemo(() => {
    let events = eventStore
      .filter((event) => event.playId === currentState.modify.playId)
      .filter((event) => event.status !== "deleted")
      .filter((event) => event.class !== "clock")
      .filter((event) => !event.options?.possible);
    let hidden_events = [];
    let child_inserts = [];
    events.forEach((event) => {
      //Find Matching Type
      workFlow.modify.types.forEach((type) => {
        if (type.eventTypes.includes(event.eventType)) {
          if (event.success === type.success || type.success === null) {
            if (type.child_inserts) {
              type.child_inserts = type.child_inserts.map((i) => ({
                ...i,
                parent: event,
              }));
              child_inserts = child_inserts.concat(type.child_inserts);
              child_inserts = child_inserts.filter(
                (child) =>
                  !(child.ignoreSubType || child.allowedSubTypes) ||
                  (child.ignoreSubType && child.parent.subType !== child.ignoreSubType) ||
                  (child.allowedSubTypes && child.allowedSubTypes.includes(child.parent.subType)) ||
                  (child.allowedEventTypes && child.allowedEventTypes.includes(child.parent.eventType)),
              );
            }
            if (!type.subType) {
              if (type.hidden_children) {
                type.hidden_children.forEach((child) => {
                  hidden_events.push(child);
                });
              }
            } else {
              switch (type.subType.operator) {
                case "is":
                  if (type.subType.subType === event.subType) {
                    if (type.hidden_children) {
                      type.hidden_children.forEach((child) => {
                        hidden_events.push(child);
                      });
                    }
                  }
                  break;
                case "not":
                  if (type.subType.subType !== event.subType) {
                    if (type.hidden_children) {
                      type.hidden_children.forEach((child) => {
                        hidden_events.push(child);
                      });
                    }
                  }
                  break;
                default:
                  break;
              }
            }
          }
        }
      });

      // create temporary goalKeeperId eventType to be able to edit goalkeeper
      if (event?.options?.goalKeeperId) {
        const defenceEntity = currentState.entities.find((entity) => entity.entityId !== event.entityId);
        const goalKeeperEvent = { ...event };
        currentState.events.goal = event;
        goalKeeperEvent.eventType = "goalKeeperId";
        goalKeeperEvent.eventId = uuidv1();
        goalKeeperEvent.personId = event.options.goalKeeperId;
        goalKeeperEvent.entityId = defenceEntity.entityId;
        delete goalKeeperEvent.subType;

        events.push(goalKeeperEvent);
      }
    });

    events = events.filter((event) => !hidden_events.includes(event.eventType));
    return { events: events, child_inserts: child_inserts };
    // eslint-disable-next-line
  }, [JSON.stringify(eventStore)]);

  useEffect(() => {
    if (playEvents.events) {
      const omitShowListModifyTypes = workFlow.modify.types
        .filter((type) => type["skipShowList"] && Object.keys(type["skipShowList"]).length > 0)
        .map((type) => type.name);
      const skipListEvent = playEvents.events.find((event) => omitShowListModifyTypes.indexOf(event.eventType) !== -1);
      if (skipListEvent) {
        currentState.events["main"] = JSON.parse(JSON.stringify(skipListEvent));
        currentState.events["edit"] = JSON.parse(JSON.stringify(skipListEvent));
        setDoOmitShowList(true);
        setShowList(false);
        const skipEventType = workFlow.modify.types.find((type) => skipListEvent.eventType === type.name);
        updateState("editState", skipEventType.skipShowList.panel);
      } else {
        setShowList(true);
      }
    }
    // eslint-disable-next-line
  }, [playEvents]);

  const eventDisplay = useMemo(() => {
    if (playEvents.events) {
      return (
        <React.Fragment>
          {playEvents.events.map((event) => {
            return (
              <EditListItem
                className="editEventListItem"
                play={currentState.modify.playId}
                key={event.eventId}
                event={event}
                {...props}
              />
            );
          })}
        </React.Fragment>
      );
    }
    // eslint-disable-next-line
  }, [playEvents]);

  const childInserts = useMemo(() => {
    if (playEvents.child_inserts) {
      let uniques = uniqueEvents(playEvents.child_inserts, (child) => child.type && child.subType);
      return (
        <React.Fragment>
          {uniques.map((insert, index) => {
            let existingChild = playEvents.events.find(
              (ev) =>
                (insert.type && ev.eventType === insert.type) ||
                (insert.allowedTypes && insert.allowedTypes.includes(ev.eventType)),
            );
            let parentChild = playEvents.events.find((ev) => ev.eventType !== insert.type);
            let shouldShow = false;
            if (insert.team) {
              if (parentChild?.personId) {
                shouldShow = false;
              }
            } else {
              if (parentChild?.personId) {
                shouldShow = true;
              }
            }
            if (insert.showAlways) {
              shouldShow = true;
            }

            if (!existingChild && insert.option) {
              const eventWithSearchedOption = playEvents.events.find(
                (ev) => findOptionsValue(insert.option, ev.options)
              );

              if (!eventWithSearchedOption) {
                shouldShow = false;
              }
            }

            if (!existingChild && shouldShow) {
              return (
                <InsertChildItem
                  className="editEventInsertItem"
                  play={currentState.modify.playId}
                  key={index}
                  insert={insert}
                  {...props}
                />
              );
            } else {
              return null;
            }
          })}
        </React.Fragment>
      );
    }
    // eslint-disable-next-line
  }, [playEvents]);

  function findOptionsValue(target, options) {
    if (!target || !options) {
      return false;
    }

    const [key, value] = Object.entries(target)[0];
    return options[key] === value;
  }

  function uniqueEvents(data, key) {
    if (data.length > 0 && data[0].ignoreSubType) {
      data = data.filter((ev) => ev.subType !== data[0].ignoreSubType);
    }
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  return doOmitShowList ? null : (
    <div className="event-list">
      <h4>
        {formatMessage({
          id: "events",
          defaultMessage: "Events",
        })}
      </h4>
      {eventDisplay}
      {childInserts}
    </div>
  );
};

export default injectIntl(EditList);
